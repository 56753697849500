import { lazy, FC, LazyExoticComponent } from 'react';

export type IconKeys =
  | 'homeIcon'
  | 'homeIconV2'
  | 'moveMoneyIcon'
  | 'moveMoneyIconV2'
  | 'rewardsIcon'
  | 'rewardsTrophyIcon'
  | 'accountIcon'
  | 'accountIconV2'
  | 'paypalTransfersIcon'
  | 'paypalTransfersIconV2'
  | 'fuelperksIcon'
  | 'cashbackIcon';

export const bottomBarIcons: Record<
  IconKeys,
  LazyExoticComponent<FC<{ color: string }>>
> = {
  homeIcon: lazy(() => import('./home-icon')),
  homeIconV2: lazy(() => import('./home-icon-v2')),
  moveMoneyIcon: lazy(() => import('./move-money-icon')),
  moveMoneyIconV2: lazy(() => import('./move-money-icon-v2')),
  rewardsIcon: lazy(() => import('./rewards-icon')),
  rewardsTrophyIcon: lazy(() => import('./rewards-trophy-icon')),
  accountIcon: lazy(() => import('./account-icon')),
  accountIconV2: lazy(() => import('./account-icon-v2')),
  paypalTransfersIcon: lazy(() => import('./paypal-transfers-icon')),
  paypalTransfersIconV2: lazy(() => import('./paypal-transfers-icon-v2')),
  fuelperksIcon: lazy(() => import('./fuelperks-icon')),
  cashbackIcon: lazy(() => import('./cashback-icon'))
};
